'use client';

import { publicPaths } from '@lib/auth';
import { updateUserLastVisitedPathnameAction } from '@server/actions/last-page/last-visited-page';
import { usePathname } from 'next/navigation';
import React from 'react';

const excludedPaths = [...publicPaths, '/join', '/not-found', '/settings/**'];

interface LastPageContextProps {}

const LastPageContext = React.createContext<LastPageContextProps>({});

export const LastPageProvider = ({
  children,
  isImpersonating
}: {
  children: React.ReactNode;
  isImpersonating: boolean;
}) => {
  const pathname = usePathname();
  const updateLastVisitedPathname = async () => {
    if (isImpersonating) {
      return;
    }

    await updateUserLastVisitedPathnameAction({ pathname });
  };

  React.useEffect(() => {
    if (
      pathname &&
      !excludedPaths.some(path =>
        path.endsWith('**') ? pathname.startsWith(path.slice(0, -2)) : path === pathname
      )
    ) {
      updateLastVisitedPathname();
    }
  }, [pathname]);

  return <LastPageContext.Provider value={{}}>{children}</LastPageContext.Provider>;
};
